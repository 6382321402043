// External
import { Injectable } from '@angular/core';

export const BITDEFENDER_PARTNER_ID = 'com.bitdefender';

export const GOOGLE_CLIENT_ID = 'gme-bitdefendersrl';

export enum  DeployType {
    BETA = 'beta',
    PROD = 'prod',
    ALPHA = 'alpha'
}

export enum AccountVersion {
    EXTERNAL = 1,
    INTERNAL = 2
}

export enum ExperienceVersions {
    V2 = 'v2',
    DEFAULT = 'default'
}

export enum Hosts {
    BETA = 'beta-central.bitdefender.com',
    ALPHA = 'alpha-central.bitdefender.com',
    STAGE = 'stage.central.nmbapp.net',
    PRODUCTION = 'central.bitdefender.com',
    LOCAL = 'v2.central.bitdefender.com',
    TEST_1 = 'test1.central.nmbapp.net',
    TEST_2 = 'test2.central.nmbapp.net',
    TEST_3 = 'test3.central.nmbapp.net',
    TEST_4 = 'test4.central.nmbapp.net',
    DEFAULT = 'default'
}

export enum ClientIds {
    BETA_NETGEAR = 'beta.netgear.nmbapp.net',
    TEST_1_NETGEAR = 'test1.netgear.nmbapp.net',
    ARMOR_NETGEAR = 'armor.netgear.com',
    ARMOR_QA_NETGEAR = 'armor-qa.netgear.com',
    ARMOR_STAGE_NETGEAR = 'armor-stg.netgear.com',
    CENTRAL_TEST_NETGEAR = 'netgear.central-test.bitdefender.biz',
    CENTRAL_OEM_NETGEAR = 'netgear.central-oem.nmbapp.net',
    CENTRAL_OEM_NETGEAR_STAGE = 'netgear.oem-central-stage.bitdefender.com'
}

/**
 * For Central we log console errors to 'sentryDSNCentralAngular' and requests error to 'sentryDSNCentralConnect'
 * For oem partners we do not usually need 2 projects to log errors so these 2 will have the same value
 */
export enum SentryDSN {
    CENTRAL = 'https://f991d9a6872cb128fe9f080491e9a299@o4504802466004992.ingest.sentry.io/4507332685529088',
    BETA_CENTRAL = 'https://f991d9a6872cb128fe9f080491e9a299@o4504802466004992.ingest.sentry.io/4507332685529088'
}

/**
 * Describes what you use from the config.service.ts config properties
 * For central bitdefender, login server === logout server, login params === logout params and login path === logout path, but
 * for oem partners this might not be the case
 */
export enum LogoutType {
    REDIRECT_TO_LOGIN_PAGE = 'redirect_to_login_page', // redirect: login server + login path + login params
    REDIRECT_TO_LOGOUT_PAGE = 'redirect_to_logout_page', // redirect: logout server + logout path + logout params
    REDIRECT_TO_RELOGIN_PAGE = 'redirect_to_relogin_page', // redirect: login server + relogin path + relogin params
    NO_REDIRECT = 'no_redirect', // redirect: no redirect
    SIMPLE = 'simple' 
}

@Injectable({
    providedIn: 'root'
})


export class ConfigService {

    config: any = {};
    conditions: any = {};
    // this variable should not be changed
    private readonly centralBitdefenderSiteUrl = 'https://armor.netgear.com/';
    private readonly centralStageBitdefenderSiteUrl = 'https://netgear.oem-central-stage.bitdefender.com';
    private readonly centralBetaBitdefenderSiteUrl = 'https://beta-central.bitdefender.com';
    private readonly centralAlphaBitdefenderSiteUrl = 'https://alpha-central.bitdefender.com';

    prodConfig: any = {
        // * Used in Development
        outletEventsTracking  : false,
        // * Core values
        //#region
        nimbusClientId        : '93e53f1f-c0ce-4a57-9b94-192359065846', // Central V2
        type                  : DeployType.PROD,
        partner_id            : 'com.netgear',
        manufacturerName      : 'NETGEAR',
        manufacturerNameBD    : 'Bitdefender',
        manufacturerNameNetgear: 'NETGEAR',
        manufacturerNameBox   : 'Bitdefender Box',
        siteName              : 'Armor',
        defaultTheme          : 'dark',
        themes                : false,
        pathImages            : '/ux/images/',
        pathIcons             : '/ux/icons/',
        logo                  : '/ux/images/netgear-images/shield-logo.svg', // app logo, if left empty manufacturerLogo will act as default
        manufacturerLogo      : '/ux/images/netgear-images/shield-logo.svg', // default logo, if left empty text logo will be default
        siteUrl               : 'https://central.bitdefender.com',
        avangateFrameParam    : '&MYACCOUNTSTYLE=nLWsnpTfpro=',
        passwordManagerOneMonthTrialId      : 'ef71cc73-3529-4a34-b1ab-a6be096206d1',
        passwordManagerThreeMonthsTrialId   : '1a0220af-6d8f-49f2-80dd-07ed6c0d56e4',
        mainPage              : '/dashboard', // first page of the website
        idTPIframeLink        : 'https://bitdefender.identityforce.com/app/.ssohandler',
        idTPIframeOrigin      : 'https://bitdefender.identityforce.com',
        // cookiesDomain should be modified only after careful testing of logout and cookie deletion, most likely should not be modified at all
        cookiesDomain                       : '.central.bitdefender',
        cookieConsent                       : false,
        // ! To be removed after proper init of products names
        boxName                           : 'NETGEAR Armor',
        //#endregion

        // * Backend URLs and Login Configuration
        //#region
        // nimbusServer           : 'https://v2.central.bitdefender.com/nimbus/',
        nimbusServer           : 'https://nimbus.bitdefender.net/',
        connectNimbusServer    : 'https://connect.nimbus.bitdefender.net/',
        katastifServer         : 'https://nimbus.bitdefender.net/',
        gmailAuth: {
            clientId: '97599827433-7sr5c4t4r2id4vgld1455omcmt465qci.apps.googleusercontent.com'
        },
        outlookAuth: {
            clientId: '2b37c4e4-f547-4076-8233-aec27bb28ad9',
        },
        loginCreateServer          : 'https://login.bitdefender.com/signup',
        loginChangeEmail             : 'https://login.bitdefender.com/validate',
        externalAccountLink: 'https://netgear.com/login',
        externalPasswordAccountLink: 'https://account.bitdefender.com/password',
        externalAuthenticationAccountLink: 'https://account.bitdefender.com/authentication',
        parentalNccDefaultAvatars  : 'https://download.bitdefender.com/resourcesv3/ncc/avatars/',
        googleMapsApi              : 'https://maps.googleapis.com/maps/api/js',

        loginServer: 'https://oauth.netgear.com/',
        loginPath: {
            [ExperienceVersions.V2]: 'login',
            [ExperienceVersions.DEFAULT]: 'oauth/auth'
        },
        loginUserTokenName: 'code',
        loginRequestType: 'oauth',
        loginParams: {
            [ExperienceVersions.V2]: {
                clientId: '$client_id',
                redirectUrl: '$redirect_url'
            },
            [ExperienceVersions.DEFAULT]: {
                adobe_mc_ref: '$ref',
                redirect_uri: '$redirect_url',
                lang: '$lang',
                partner_id: '$partner_id',
                response_type: 'code',
                client_id: '$client_id',
                scope: 'profile,email',
                state: 'auth',
                consent_prompt: 'auto'
            }
        },
        useClientIds: true,
        clientIds: {
            [ExperienceVersions.V2]: {
                [ClientIds.ARMOR_QA_NETGEAR]: "397t7kpmhm516ml159dl3ggtsk"
            },
            [ExperienceVersions.DEFAULT]: {
                [ClientIds.ARMOR_NETGEAR]: "jeufnvmxdkpweryqacnl-oauth-netgear",
                [ClientIds.ARMOR_QA_NETGEAR]: "dkmvuwopamzfnrhgbqpa-oauth-netgear",
                [ClientIds.BETA_NETGEAR]: "gusvhjqbfvpbyzkuvilj-oauth-netgear",
                [ClientIds.TEST_1_NETGEAR]: "eiphajwlaelbgkvulbkz-oauth-netgear",
                [ClientIds.CENTRAL_TEST_NETGEAR]: "ewdjscnvmxkhqwyatzns-oauth-netgear",
                [ClientIds.ARMOR_STAGE_NETGEAR]: "weeuovbyewisyxvbmapj-oauth-netgear",
                [ClientIds.CENTRAL_OEM_NETGEAR]: "gmtlwcvayxagzlpoysyo-oauth-netgear",
                [ClientIds.CENTRAL_OEM_NETGEAR_STAGE]: "vxewdmvajnxgvabjgaop-oauth-netgear"
            }
        },
        reloginParams: {
            redirect_url: '$redirect_url',
            lang: '$lang',
            user: '$email',
            des : 'welcome'
        },
        logoutServer   : 'https://oauth.netgear.com/',
        logoutPath: {
            [ExperienceVersions.V2]: 'logout',
            [ExperienceVersions.DEFAULT]: 'oauth/logout'
        },
        logoutParams: {
            [ExperienceVersions.V2]: {
                redirectUrl: '$redirect_url'
            },
            [ExperienceVersions.DEFAULT]: {
                // adobe_mc_ref: '$ref',
                // lang: '$lang',
                // partner_id: '$partner_id',
                redirect_uri: '$redirect_url',
                response_type: 'code',
                access_type: 'offline',
                client_id: '$client_id',
                scope: 'profile,email',
                consent_prompt: 'auto'
            }
        },
        // ? posible values: host, host:slash, host:path, host:path:query, host:query. Everything else translates to only <host>
        loginRedirectUrlType: 'host:slash', // this is used for both login and logout and is not configurable for both of them
        autolookupPath: 'central/password.html',
        defaultLoginUserTokenName: 'user_token',

        registerServer    : 'https://login.bitdefender.com/',
        registerPath      : 'central/signup.html',
        registerParams    : {
            adobe_mc_ref: '$ref',
            redirect_url: '$redirect_url',
            lang: '$lang'
        },
        //#endregion

        // * Modules switches
        //#region
        notifications     : true, // Notifications drawer
        premium           : false, // Premium services
        privacyTabs: {
            activity: true,
            footprint  : true,
            breaches  : true,
            impersonations  : true,
            brokers  : true,
            education: true,
            history: true,
            monitor: true
        },
        privacyChat: true,
        subscriptions     : true, // Subscriptions
        subscriptionsTabs : {
            billing : true,
            invoices: true,
            payments: true
        },
        subscriptionsSdk: false,
        autoRenewalFlow: true,
        multiplePaymentCards: false,
        quickActions: {
            malwareScan       : true,
            antitheft         : true,
            optimizer         : true,
            vulnerabilityScan : true,
            scan              : true
        },
        offers            : true, // Remove offers module + navigation
        support           : true, // Remove support button from left menu
        community         : false,
        thirdparty        : true,
        terms             : true, // Remove Terms of service link
        poweredBy         : false, // Show 'powered by Bitdefender' logo
        dashboard         : true, // remove dashboard page. this is also changeable in different files in the app.
        omniture          : true,
        devicesTab        : true, //! De redenumit :-<
        business: {
            dashboard: true
        },
        dashboardCards: {
            recommendations: true,
            securityNews: true,
            appBanner: true
        },
        referral          : true,
        info              : true,
        onboardingMSP     : true, // Remove Onboarding MSP
        qrCode            : true, // Display QR Code on install flow

        //#endregion

        // Support
        internalSupport: false,
        externalSupport: true,
        externalSupportLink: '',
        supportCards: {
            phone: false,
            email: false,
            chat: false,
            search: false
        },
        //#endregion

        // * Home Module
        //#region
        home: false, // Homepage
        homeSections: {
            capabilities: true,
            capabilitiesList: {
                deviceProtection: true,
                remoteActions: true,
                installProtection: true,
                manageServices: true,
                parentalControl: true,
                industryInsights: true
            },
            products: true,
            appBanner: true,
        },
        //#endregion

        // * Devices Module
        //#region
        devices: {
            box: {
                settings: {
                    wifi: false,
                    changename: false,
                    exceptions: true,
                    reset: false
                }
            },
            install_button: true,
            install: {
                win_xp_link: true,
                ios: true
            },
            card: {
                remove_button: true // Remove delete device button from card on all types of devices
            },
            filters: {
                status: false,
                platform: true
            }
        },
        hasCustomBuyLink: false,
        //#endregion

        // * Account Module
        //#region
        account: {
            profile: true,
            sessions: true,
            settings: true,
            change_password: true,
            admin_password: true,
            delete_account: true,
            change_email: true,
            version: AccountVersion.EXTERNAL
        },
        //#endregion

        // * Subscriptions Module
        //#region
        activationButtonDoRedirect: false,
        serviceDetailsLinks: {
            website: true,
            features: false,
            requirements: false,
            userGuide: false
        },
        //#endregion

        // * Password Manager Module
        //#region
        passwordManagerCards: {
            needHelp: true
        },
        //#endregion

        // * VPN Module
        //#region
        vpnCards: {
            upgrade: true,
            userGuide: false
        },
        //#endregion

        downloadElements: {
            footer: false,
            headerLogo: true,
            benefitsLogo: true,
            commonHelpSection: true
        },

        parentalNccElements: {
            contentFiltering: {
                module: true,
                topics: true,
                safeSearch: true,
                youtubeRestricted: true,
            },
            dtl: {
                module: true,
                reward: true,
                needMoreTime: true
            },
            routines: {
                module: true
            },
            dashboard: {
                module: true,
                location: true,
            },
            blockedContent: {
                hasCategories: false,
                hasTopics: false,
                hasWebsites: true
            },
            pin: true
        },

        security: true,
        securityTabs: {
            overview: true,
            webmailProtection: true
        },

        installProtection : {
            //If showLogo is true and customLogoPath is blank, the default app logo will be used
            //If showLogo is true and customLogoPath is set, the custom logo will be used
            showLogo: false, //Show logo instead of icon in install protection modal
            customLogoPath: "", // Path to logo svg file(must have id="logo") or blank for app logo
        },

        katastifService: true,
        sentryAngular: true,
        sentryConnect: true,

        rights: {
            buy: true,
            buyMoreSlots: true
        },

        pwaSettings: {
            manifestByOs: {
                windows: './assets/manifests/windows.webmanifest',
                android: './assets/manifests/android.webmanifest',
                mac: './assets/manifests/mac.webmanifest',
                ios: './assets/manifests/ios.webmanifest',
                osx: './assets/manifests/mac.webmanifest'
            },
            manifestPath: './assets/manifests/default.webmanifest',
            serviceWorkerPath: '/master-sw.js'
        }
    };

    betaConfig: any = {
        // * Core values
        //#region
        nimbusClientId        : '93e53f1f-c0ce-4a57-9b94-192359065846', // Central V2
        type                  : DeployType.BETA,
        partner_id            : 'com.netgear',
        manufacturerName      : 'NETGEAR',
        manufacturerNameBD    : 'Bitdefender',
        manufacturerNameNetgear: 'NETGEAR',
        manufacturerNameBox   : 'Bitdefender Box',
        siteName              : 'Armor',
        defaultTheme          : 'dark',
        themes                : false,
        pathImages            : '/ux/images/',
        pathIcons             : '/ux/icons/',
        logo                  : '/ux/images/netgear-images/shield-logo.svg', // app logo, if left empty manufacturerLogo will act as default
        manufacturerLogo      : '/ux/images/logo/manufacturerLogo.svg', // default logo, if left empty text logo will be default
        siteUrl               : 'https://beta.central.bitdefender.com',
        avangateFrameParam    : '&MYACCOUNTSTYLE=nLWsnpTfpro=',
        passwordManagerOneMonthTrialId      : 'ef71cc73-3529-4a34-b1ab-a6be096206d1',
        passwordManagerThreeMonthsTrialId   : '1a0220af-6d8f-49f2-80dd-07ed6c0d56e4',
        mainPage              : '/dashboard', // first page of the website
        idTPIframeLink        : 'https://bitdefender-rakf6ypxhkjmq4ys8xe5mekepc.stage-alt.ezstest.com/app/.ssohandler',
        idTPIframeOrigin      : 'https://bitdefender-rakf6ypxhkjmq4ys8xe5mekepc.stage-alt.ezstest.com',
        // cookiesDomain should be modified only after careful testing of logout and cookie deletion, most likely should not be modified at all
        cookiesDomain                       : '.central.bitdefender',
        cookieConsent                       : false,
        //#endregion

        // * Backend URLs and Login Configuration
        //#region
        nimbusServer           : 'https://beta.nimbus.bitdefender.net/',
        connectNimbusServer    : 'https://beta.nimbus.bitdefender.net/',
        katastifServer         : 'https://nimbus.bitdefender.net/',
        gmailAuth: {
            clientId: '97599827433-7sr5c4t4r2id4vgld1455omcmt465qci.apps.googleusercontent.com'
        },
        outlookAuth: {
            clientId: 'ba480ec4-33f3-4f40-bc39-e80c36ccc5f2',
        },

        loginCreateServer          : 'https://beta-login.bitdefender.com/signup',
        loginChangeEmail             : 'https://login.bitdefender.com/validate',
        externalAccountLink: 'https://netgear.com/login',
        externalPasswordAccountLink: 'https://account.bitdefender.com/password',
        externalAuthenticationAccountLink: 'https://account.bitdefender.com/authentication',
        parentalNccDefaultAvatars  : 'https://download.bitdefender.com/resourcesv3/ncc/avatars/',
        googleMapsApi              : 'https://maps.googleapis.com/maps/api/js',

        loginServer: 'https://oauthqa2.netgear.com/',
        loginPath: {
            [ExperienceVersions.DEFAULT]: 'login'
        },
        loginUserTokenName: 'code',
        loginRequestType: 'oauth',
        loginParams: {
            [ExperienceVersions.V2]: {
                clientId: '$client_id',
                redirectUrl: '$redirect_url'
            },
            [ExperienceVersions.DEFAULT]: {
                // adobe_mc_ref: '$ref',
                // lang: '$lang',
                // partner_id: '$partner_id',
                response_type: 'code',
                client_id: '$client_id',
                scope: 'email openid profile',
                redirect_uri: '$redirect_url'
            }
        },
        useClientIds: true,
        clientIds: {
            [ExperienceVersions.V2]: {
                [ClientIds.ARMOR_QA_NETGEAR]: "397t7kpmhm516ml159dl3ggtsk"
            },
            [ExperienceVersions.DEFAULT]: {
                [ClientIds.ARMOR_NETGEAR]: "jeufnvmxdkpweryqacnl-oauth-netgear",
                [ClientIds.ARMOR_QA_NETGEAR]: "23blej6l4vsnk14i6gdp2qduod",
                [ClientIds.BETA_NETGEAR]: "gusvhjqbfvpbyzkuvilj-oauth-netgear",
                [ClientIds.TEST_1_NETGEAR]: "eiphajwlaelbgkvulbkz-oauth-netgear",
                [ClientIds.CENTRAL_TEST_NETGEAR]: "ewdjscnvmxkhqwyatzns-oauth-netgear",
                [ClientIds.ARMOR_STAGE_NETGEAR]: "weeuovbyewisyxvbmapj-oauth-netgear",
                [ClientIds.CENTRAL_OEM_NETGEAR]: "myrannnvazfksmpvrcjb-oauth-netgear"
            }
        },
        reloginParams: {
            redirect_url: '$redirect_url',
            lang: '$lang',
            user: '$email',
            des : 'welcome'
        },
        logoutServer: 'https://oauthqa2.netgear.com/',
        logoutPath: {
            [ExperienceVersions.V2]: 'logout',
            [ExperienceVersions.DEFAULT]: 'login'
        },
        logoutParams: {
            [ExperienceVersions.V2]: {
                redirectUrl: '$redirect_url'
            },
            [ExperienceVersions.DEFAULT]: {
                // adobe_mc_ref: '$ref',
                // lang: '$lang',
                // partner_id: '$partner_id',
                redirect_uri: '$redirect_url',
                response_type: 'code',
                access_type: 'offline',
                client_id: '$client_id',
                scope: 'email openid profile'
            }
        },
        // ? posible values: host, host:slash, host:path, host:path:query, host:query. Everything else translates to only <host>
        loginRedirectUrlType: 'host', // this is used for both login and logout and is not configurable for both of them
        autolookupPath: 'central/password.html',
        defaultLoginUserTokenName: 'user_token',

        registerServer: 'https://beta-login.bitdefender.com/',
        registerPath  : 'central/signup.html',
        registerParams: {
            adobe_mc_ref: '$ref',
            redirect_url: '$redirect_url',
            lang: '$lang'
        },
        //#endregion

        // * Modules switches
        //#region
        notifications     : true, // Notifications drawer
        premium           : false, // Premium services
        privacyTabs: {
            activity: true,
            footprint  : true,
            breaches  : true,
            impersonations  : true,
            brokers  : true,
            education: true,
            history: true,
            monitor: true
        },
        privacyChat: true,
        subscriptions     : true, // Subscriptions
        subscriptionsTabs : {
            billing : true,
            invoices: true,
            payments: true
        },
        subscriptionsSdk: false,
        autoRenewalFlow: true,
        multiplePaymentCards: false,
        quickActions: {
            malwareScan       : true,
            antitheft         : true,
            optimizer         : true,
            vulnerabilityScan : true,
            scan              : true
        },
        offers            : true, // Remove offers module + navigation
        support           : true, // Remove support button from left menu
        community         : false,
        thirdparty        : true,
        terms             : true, // Remove Terms of service link
        poweredBy         : false, // Show 'powered by Bitdefender' logo
        dashboard         : true, // remove dashboard page. this is also changeable in different files in the app.
        omniture          : true,
        devicesTab        : true, //! De redenumit :-<
        business: {
            dashboard: true
        },
        dashboardCards: {
            recommendations: true,
            securityNews: true,
            appBanner: true
        },
        referral          : true,
        info              : true,
        onboardingMSP     : true, // Remove Onboarding MSP
        qrCode            : true, // Display QR Code on install flow

        //#endregion

        // END Root

        // Support
        internalSupport: false,
        externalSupport: true,
        externalSupportLink: '',
        supportCards: {
            phone: false,
            email: false,
            chat: false,
            search: false
        },
        //#endregion

        // * Home Module
        //#region
        home: false, // Homepage
        homeSections: {
            capabilities: true,
            capabilitiesList: {
                deviceProtection: true,
                remoteActions: true,
                installProtection: true,
                manageServices: true,
                parentalControl: true,
                industryInsights: true
            },
            products: true,
            appBanner: true,
        },
        //#endregion

        // * Devices Module
        //#region
        devices: {
            box: {
                settings: {
                    wifi: false,
                    changename: false,
                    exceptions: true,
                    reset: false
                }
            },
            install_button: true,
            install: {
                win_xp_link: true,
                ios: true
            },
            card: {
                remove_button: true // Remove delete device button from card on all types of devices
            },
            filters: {
                status: false,
                platform: true
            }
        },
        hasCustomBuyLink: false,
        //#endregion

        // * Account Module
        //#region
        account: {
            profile: true,
            sessions: true,
            settings: true,
            change_password: true,
            admin_password: true,
            delete_account: true,
            change_email: true,
            version: AccountVersion.EXTERNAL
        },
        //#endregion

        // * Subscriptions Module
        //#region
        activationButtonDoRedirect: false,
        serviceDetailsLinks: {
            features: false,
            requirements: false,
            userGuide: false
        },
        //#endregion

        // * Password Manager Module
        //#region
        passwordManagerCards: {
            needHelp: true
        },
        //#endregion

        // * VPN Module
        //#region
        vpnCards: {
            upgrade: true,
            userGuide: false
        },
        //#endregion

        downloadElements: {
            footer: false,
            headerLogo: true,
            benefitsLogo: true,
            commonHelpSection: true
        },

        parentalNccElements: {
            contentFiltering: {
                module: true,
                topics: true,
                safeSearch: true,
                youtubeRestricted: true,
            },
            dtl: {
                module: true,
                reward: true,
                needMoreTime: true
            },
            routines: {
                module: true
            },
            dashboard: {
                module: true,
                location: true,
            },
            blockedContent: {
                hasCategories: false,
                hasTopics: false,
                hasWebsites: true
            },
            pin: true
        },

        security: true,
        securityTabs: {
            overview: true,
            webmailProtection: true
        },

        installProtection : {
            showLogo: false, //Show logo instead of icon in install protection modal
            logoPath: "", // Path to logo svg file(must have id=logo) or blank for app logo
        },

        katastifService: true,
        sentryAngular: true,
        sentryConnect: true,

        rights: {
            buy: true,
            buyMoreSlots: true
        },

        pwaSettings: {
            manifestByOs: {
                windows: './assets/manifests/windows.webmanifest',
                android: './assets/manifests/android.webmanifest',
                mac: './assets/manifests/mac.webmanifest',
                ios: './assets/manifests/ios.webmanifest',
                osx: './assets/manifests/mac.webmanifest'
            },
            manifestPath: './assets/manifests/default.webmanifest',
            serviceWorkerPath: '/master-sw.js'
        }
    };

    constructor() {
        const _glCnfg = window['_glCnfg'];
        if (_glCnfg && _glCnfg.type === 311) {
            this.config = this.betaConfig;
        } else {
            this.config = this.prodConfig;
        }
        this.conditions = JSON.parse(JSON.stringify(this.config));
    }

    get() {
        return this.config;
    }

    deployTypeIsBeta() {
        return this.config.type === DeployType.BETA;
    }

    deployTypeIsProd() {
        return this.config.type === DeployType.PROD;
    }

    getDeployType(): DeployType {
        return this.config.type;
    }

    accountIsExternal() {
        return this.config?.account?.version === AccountVersion.EXTERNAL;
    }

    getChangePassword() {
        return this.conditions?.account?.change_password;
    }

    getChangeEmail() {
        return this.conditions?.account?.change_email;
    }

    getDeleteAccount() {
        return this.conditions?.account?.delete_account;
    }

    getAdminPassword() {
        return this.conditions?.account?.admin_password;
    }

    getOriginalAdminPassword() {
        return this.config?.account?.admin_password;
    }

    setAccountPaswword(showAdminPassword) {
        this.conditions.account.admin_password = showAdminPassword;
    }

    getAccountProfile() {
        return this.conditions?.account?.profile;
    }

    getAccountSettings() {
        return this.conditions?.account?.settings;
    }

    getAccountSessions() {
        return this.conditions?.account?.sessions;
    }

    getReccomendationsCard() {
        return this.conditions?.dashboard && this.conditions?.dashboardCards?.recommendations;
    }

    getNotifications() {
        return this.conditions?.notifications;
    }

    /**
     * Gets the preseted value of notifications config flag
     * @returns {boolean} the original value of notifications config flag
     */
    public getOriginalNotifications(): boolean {
        return this.config?.notifications;
    }

    public getOriginalBusinessDashboard(): boolean {
        return this.config?.business?.dashboard;
    }

    setNotifications(showNotifications) {
        this.conditions.notifications = showNotifications;
    }

    getDashboard() {
        return this.conditions?.dashboard;
    }

    public getBusinessDashboard(): boolean {
        return this.conditions?.business?.dashboard;
    }

    /**
     * Gets the preseted value of dashboard page config flag
     * @returns {boolean} the original value of dashboard page config flag
     */
    public getOriginalDashboard(): boolean {
        return this.config?.dashboard;
    }

    setDashboard(showDashboard) {
        this.conditions.dashboard = showDashboard;
    }

    public setBusinessDashboard(showDashboard): void {
        this.conditions.business.dashboard = showDashboard;
    }

    getDevices() {
        return this.conditions?.devicesTab;
    }

    /**
     * Gets the preseted value of devices page config flag
     * @returns {boolean} the original value of devices page config flag
     */
    public getOriginalDevices(): boolean {
        return this.config?.devicesTab;
    }

    setDevices(showDevicesTab) {
        this.conditions.devicesTab = showDevicesTab;
    }

    getPremiumServices() {
        return this.conditions?.premium;
    }

    getOriginalPremiumServices() {
        return this.config?.premium;
    }

    setPremiumServices(showPremiumServices) {
        this.conditions.premium = showPremiumServices;
    }

    getSubscriptions() {
        return this.conditions?.subscriptions;
    }

    getOffers() {
        return this.conditions?.offers;
    }

    /**
     * Gets the preseted value of offers page config flag
     * @returns {boolean} the original value of offers page config flag
     */
    public getOriginalOffers(): boolean {
        return this.config?.offers;
    }

    setOffers(showOffers) {
        this.conditions.offers = showOffers;
    }

    getReferral() {
        return this.conditions?.referral;
    }

    /**
     * Gets the preseted value of referral config flag
     * @returns {boolean} the original value of referral config flag
     */
    public getOriginalReferral(): boolean {
        return this.config?.referral;
    }

    setReferral(showReferral) {
        this.conditions.referral = showReferral;
    }

    /**
     * Gets the flag for insternal support
     * @returns {boolean} True if internal support is enabled
     */
    public getInternalSupport(): boolean {
        return this.conditions?.internalSupport;
    }

    /**
     * Gets whether support is available or not (inernal or external)
     * @returns {boolean} True if support is available
     */
    public getSupport(): boolean {
        return this.getInternalSupport() || this.getExternalSupport();
    }

    /**
     * Gets the preseted value of support page config flag
     * @returns {boolean} the original value of support page config flag
     */
    public getOriginalSupport(): boolean {
        return this.config?.support;
    }

    /**
     * Gets the preseted value of multiple payment cards used in payment details modal
     * @public
     * @memberof ConfigService
     * @returns {boolean} the original value of config flag
     */
    public getMultiplePaymentCards(): boolean {
        return this.conditions?.multiplePaymentCards;
    }

    /**
     * Sets the computed value of support page config flag
     * @param {boolean} showSupport True if support page should be shown
     */
    public setSupport(showSupport: boolean): void {
        this.conditions.support = showSupport;
    }

    getSupportContact() {
        return (this.getSupportEmail() || this.getSupportPhone());
    }

    getInfo() {
        return this.conditions?.info;
    }

    getOnboardingMSP() {
        return this.conditions?.onboardingMSP;
    }

    getOriginalCommunity() {
        return this.config?.community;
    }

    getCommunity() {
        return this.conditions?.community;
    }

    setNCCTopics(topics) {
        this.conditions.parentalNccElements.contentFiltering.topics = topics;
    }

    setNCCSafeSearch(safeSearch) {
        this.conditions.parentalNccElements.contentFiltering.safeSearch = safeSearch;
    }

    setNCCYoutubeRestricted(youtubeRestricted) {
        this.conditions.parentalNccElements.contentFiltering.youtubeRestricted = youtubeRestricted;
    }

    setNCCReward(reward) {
        this.conditions.parentalNccElements.dtl.reward = reward;
    }

    setNCCNeedMoreTime(needMoreTime) {
        this.conditions.parentalNccElements.dtl.needMoreTime = needMoreTime;
    }

    setNCCRoutines(routines) {
        this.conditions.parentalNccElements.routines.module = routines;
    }

    setNCCLocation(location) {
        this.conditions.parentalNccElements.dashboard.location = location;
    }

    /**
     * Set Ncc Blocked content categories state
     * @param {boolean} state
     * @memberof ConfigService
     */
    setNCCBlockedContentHasCategories(state: boolean): void {
        this.conditions.parentalNccElements.blockedContent.hasCategories = state;
    }
    /**
     * Set Ncc Blocked content topics state
     * @param {boolean} state
     * @memberof ConfigService
     */
    setNCCBlockedContentHasTopics(state: boolean): void {
        this.conditions.parentalNccElements.blockedContent.hasTopics = state;
    }

    /**
     * Set Ncc Blocked content websites state
     * @param {boolean} state
     * @memberof ConfigService
     */
    setNCCBlockedContentHasWebsites(state: boolean): void {
        this.conditions.parentalNccElements.blockedContent.hasWebsites = state;
    }

    getOriginalNCCTopics() {
        return this.config.parentalNccElements.contentFiltering.topics;
    }

    getOriginalNCCSafeSearch() {
        return this.config.parentalNccElements.contentFiltering.safeSearch;
    }

    getOriginalNCCYoutubeRestricted() {
        return this.config.parentalNccElements.contentFiltering.youtubeRestricted;
    }

    getOriginalNCCReward() {
        return this.config.parentalNccElements.dtl.reward;
    }

    getOriginalNCCNeedMoreTime() {
        return this.config.parentalNccElements.dtl.needMoreTime;
    }

    getOriginalNCCRoutines() {
        return this.config.parentalNccElements.routines.module;
    }

    getOriginalNCCLocation() {
        return this.config.parentalNccElements.dashboard.location;
    }

    /**
     * Get Original Ncc Blocked content categories state
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getOriginalNCCBlockedContentHasCategories(): boolean {
        return this.config.parentalNccElements.blockedContent.hasCategories;
    }

    /**
     * Get Original Ncc Blocked content topics state
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getOriginalNCCBlockedContentHasTopics(): boolean {
        return this.config.parentalNccElements.blockedContent.hasTopics;
    }

    /**
     * Get Original Ncc Blocked content websites state
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getOriginalNCCBlockedContentHasWebsites(): boolean {
        return this.config.parentalNccElements.blockedContent.hasWebsites;
    }

    getNCCTopics() {
        return this.conditions.parentalNccElements.contentFiltering.topics;
    }

    getNCCSafeSearch() {
        return this.conditions.parentalNccElements.contentFiltering.safeSearch;
    }

    getNCCYoutubeRestricted() {
        return this.conditions.parentalNccElements.contentFiltering.youtubeRestricted;
    }

    getNCCReward() {
        return this.conditions.parentalNccElements.dtl.reward;
    }

    getNCCNeedMoreTime() {
        return this.conditions.parentalNccElements.dtl.needMoreTime;
    }

    getNCCRoutines() {
        return this.conditions.parentalNccElements.routines.module;
    }

    getNCCLocation() {
        return this.conditions.parentalNccElements.dashboard.location;
    }

    getNCCPin() {
        return this.conditions.parentalNccElements.pin;
    }

    /**
     * Get Ncc Blocked content categories state
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getNCCBlockedContentHasCategories(): boolean {
        return this.conditions.parentalNccElements.blockedContent.hasCategories;
    }

    /**
     * Get Ncc Blocked content topics state
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getNCCBlockedContentHasTopics(): boolean {
        return this.conditions.parentalNccElements.blockedContent.hasTopics;
    }

    /**
     * Get Ncc Blocked content websites state
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getNCCBlockedContentHasWebsites(): boolean {
        return this.conditions.parentalNccElements.blockedContent.hasWebsites;
    }

    /**
     * Get Ncc Blocked content state if we have catgories and another tab
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getNCCBlockedContentCategoriesAndOther(): boolean {
        return this.getNCCBlockedContentHasCategories()
                && (this.getNCCBlockedContentHasTopics()
                || this.getNCCBlockedContentHasWebsites());
    }

    /**
     * Get Ncc Blocked content state
     * @return {*}  {boolean}
     * @memberof ConfigService
     */
    getNCCBlockedContent(): boolean {
        return this.getNCCBlockedContentHasCategories()
                || this.getNCCBlockedContentHasTopics()
                || this.getNCCBlockedContentHasWebsites();
    }

    setCommunity(showCommunity) {
        this.conditions.community = showCommunity;
    }

    getSubscriptionsBilling() {
        return this.conditions?.subscriptionsTabs?.billing;
    }

    setSubscriptionsBilling(showSubscriptionsBilling) {
        this.conditions.subscriptionsTabs.billing = showSubscriptionsBilling;
    }

    getSubscriptionsInvoices() {
        return this.conditions?.subscriptionsTabs?.invoices;
    }

    setSubscriptionsInvoices(showSubscriptionsInvoices) {
        this.conditions.subscriptionsTabs.invoices = showSubscriptionsInvoices;
    }

    getSubscriptionsPayments() {
        return this.conditions?.subscriptionsTabs?.payments;
    }

    setSubscriptionsPayments(showSubscriptionsPayments) {
        this.conditions.subscriptionsTabs.payments = showSubscriptionsPayments;
    }

    getSubscriptionsTab(pathname) {
        return this.conditions?.subscriptionsTabs?.[pathname];
    }

    getSupportEmail() {
        return this.conditions?.support && this.conditions?.supportCards?.email;
    }

    /**
     * Gets the flag for privacy chat
     * @returns {boolean} True if the chat can appear
     */
    public getPrivacyChat(): boolean {
        return this.conditions?.privacyChat;
    }

    getSupportPhone() {
        return this.conditions?.support && this.conditions?.supportCards?.phone;
    }

    getSupportSearch() {
        return this.conditions?.support && this.conditions?.supportCards?.search;
    }

    getOriginalSupportSearch() {
        return this.config?.support && this.config?.supportCards?.search;
    }

    setSupportSearch(showSupportSearch) {
        this.conditions.supportCards.search = showSupportSearch;
    }

    getDashboardSecurityNews() {
        return this.conditions?.dashboard && this.conditions?.dashboardCards?.securityNews;
    }

    getDashboarAppBanner() {
        return this.conditions?.dashboard && this.conditions?.dashboardCards?.appBanner;
    }

    getSiteUrl() {
        return this.config?.siteUrl;
    }

    getCentralBitdefenderSiteUrl() {
        return this.centralBitdefenderSiteUrl;
    }

    getCentralStageBitdefenderSiteUrl() {
        return this.centralStageBitdefenderSiteUrl;
    }

    getCentralBetaBitdefenderSiteUrl() {
        return this.centralBetaBitdefenderSiteUrl;
    }

    getCentralAlphaBitdefenderSiteUrl() {
        return this.centralAlphaBitdefenderSiteUrl;
    }

    getChangeEmailUrl() {
        return this.config?.loginChangeEmail;
    }

    /**
     * Get external link for Account Authentication page
     * @return {*}  {string}
     * @memberof ConfigService
     */
    public getExternalAuthenticationAccountLink(): string {
        return this.conditions?.externalAuthenticationAccountLink;
    }

    getCookiesDomain() {
        return this.config?.cookiesDomain;
    }

    getQrCode() {
        return this.conditions?.qrCode;
    }

    getCookieConsent() {
        return this.conditions?.cookieConsent;
    }

    getOmniture() {
        return this.conditions?.omniture;
    }

    getSubscriptionsSdk() {
        return this.conditions?.subscriptionsSdk;
    }

    getManufacturerName() {
        return this.conditions?.manufacturerName;
    }

    getDownloadFooter() {
        return this.conditions?.downloadElements?.footer;
    }

    getDownloadHeaderLogo() {
        return this.conditions?.downloadElements?.headerLogo;
    }

    getDownloadBenefitsLogo() {
        return this.conditions?.downloadElements?.benefitsLogo;
    }

    getDownloadCommonHelpSection() {
        return this.conditions?.downloadElements?.commonHelpSection;
    }

    getPasswordManagerNeedHelp() {
        return this.conditions?.passwordManagerCards?.needHelp;
    }

    getVpnUpgrade() {
        return this.conditions?.vpnCards?.upgrade;
    }

    getVpnUserGuide() {
        return this.conditions?.vpnCards?.userGuide;
    }

    getKatastifService() {
        return this.conditions?.katastifService;
    }

    getSentryAngular() {
        return this.conditions?.sentryAngular;
    }

    getSentryConnect() {
        return this.conditions?.sentryConnect;
    }

    getExternalSupport() {
        return this.conditions?.externalSupport;
    }

    /**
     * Gets the preseted value of external support config flag
     * @returns {boolean} the original value of external support config flag
     */
    public getOriginalExternalSupport(): boolean {
        return this.config?.externalSupport;
    }

    /**
     * Gets the preseted value of auto renewal flow config flag
     * @public
     * @memberof ConfigService
     * @returns {boolean} The value of the flag
     */
    public getAutoRenewalFlow(): boolean {
        return this.conditions?.autoRenewalFlow;
    }

    setExternalSupport(showExternalSupport) {
        this.conditions.externalSupport = showExternalSupport;
    }

    /**
     * Gets the preseted value of service details website link config flag
     * @public
     * @memberof ConfigService
     * @returns {boolean} The original value of the websites flag
     */
    public getServiceDetailsWebsite(): boolean {
        return !!this.conditions?.serviceDetailsLinks?.website;
    }

    getServiceDetailsFeatures() {
        return this.conditions?.serviceDetailsLinks?.features;
    }

    getServiceDetailsRequirements() {
        return this.conditions?.serviceDetailsLinks?.requirements;
    }

    getServiceDetailsUserGuide() {
        return this.conditions?.serviceDetailsLinks?.userGuide;
    }

    getHome() {
        return this.conditions?.home;
    }

    getHomeSectionCapabilities() {
        return this.conditions?.homeSections.capabilities;
    }

    getHomeSectionProducts() {
        return this.conditions?.homeSections.products;
    }

    getHomeSectionAppBanner() {
        return this.conditions?.homeSections.appBanner;
    }

    getHomeSectionCapabilityDeviceProtection() {
        return this.conditions?.homeSections.capabilitiesList.deviceProtection;
    }

    getHomeSectionCapabilityRemoteActions() {
        return this.conditions?.homeSections.capabilitiesList.remoteActions;
    }

    getHomeSectionCapabilityInstallProtection() {
        return this.conditions?.homeSections.capabilitiesList.installProtection;
    }

    getHomeSectionCapabilityManageServices() {
        return this.conditions?.homeSections.capabilitiesList.manageServices;
    }

    getHomeSectionCapabilityParentalControl() {
        return this.conditions?.homeSections.capabilitiesList.parentalControl;
    }

    getHomeSectionCapabilityIndustryInsights() {
        return this.conditions?.homeSections.capabilitiesList.industryInsights;
    }

    getInstallProtectionShowLogo() {
        return this.config.installProtection.showLogo;
    }

    getInstallProtectionLogoPath() {
        return this.config.installProtection.customLogoPath !== "" ? this.config.installProtection.customLogoPath : null;
    }

    setPrivacyActivity(showPrivacyActivity) {
        this.conditions.privacyTabs.activity = showPrivacyActivity;
    }

    getPrivacyActivity() {
        return this.conditions?.privacyTabs?.activity;
    }

    setPrivacyFootprint(showPrivacyFootprint) {
        this.conditions.privacyTabs.footprint = showPrivacyFootprint;
    }

    getPrivacyFootprint() {
        return this.conditions?.privacyTabs?.footprint;
    }

    setPrivacyBreaches(showPrivacyBreaches) {
        this.conditions.privacyTabs.breaches = showPrivacyBreaches;
    }

    getPrivacyBreaches() {
        return this.conditions?.privacyTabs?.breaches;
    }

    setPrivacyImpersonations(showPrivacyImpersonations) {
        this.conditions.privacyTabs.impersonations = showPrivacyImpersonations;
    }

    getPrivacyImpersonations() {
        return this.conditions?.privacyTabs?.impersonations;
    }

    setPrivacyBrokers(showPrivacyBrokers) {
        this.conditions.privacyTabs.brokers = showPrivacyBrokers;
    }

    getPrivacyBrokers() {
        return this.conditions?.privacyTabs.brokers;
    }

    setPrivacyEducation(showPrivacyEducation) {
        this.conditions.privacyTabs.education = showPrivacyEducation;
    }

    getPrivacyEducation() {
        return this.conditions?.privacyTabs.education;
    }

    setPrivacyHistory(showPrivacyHistory) {
        this.conditions.privacyTabs.history = showPrivacyHistory;
    }

    getPrivacyHistory() {
        return this.conditions?.privacyTabs.history;
    }

    setPrivacyMonitor(showPrivacyMonitor) {
        this.conditions.privacyTabs.monitor = showPrivacyMonitor;
    }

    getPrivacyMonitor() {
        return this.conditions?.privacyTabs.monitor;
    }

    /**
     * Gets the gmail auth client id.
     * @returns {string} The gmail auth client id.
     */
    public getGmailAuthClientId(): string {
        return this.conditions?.gmailAuth?.clientId;
    }

    /**
     * Gets the outlook auth client id.
     * @returns {string} The outlook auth client id.
     */
    public getOutlookAuthClientId(): string {
        return this.conditions?.outlookAuth?.clientId;
    }

    /**
     * Get Google Maps Api link
     * @return {*}  {string}
     * @memberof ConfigService
     */
    public getGoogleMapsApiLink(): string {
        return this?.conditions?.googleMapsApi ?? '';
    }

    /**
     * Gets the config flag for security tab.
     * @returns {boolean} True if the security tab should be shown.
     */
    public getSecurityModule(): boolean {
        return this.conditions?.security;
    }

    /**
     * Sets the config flag for security tab.
     * @param {boolean} security True if the security tab should be shown.
     */
    public setSecurityModule(security: boolean): void {
        this.conditions.security = security;
    }

    /**
     * Gets the original config flag for security module.
     * @returns {boolean} True if the security module should be shown.
     */
    public getOriginalSecurityModule(): boolean {
        return this.config?.security;
    }

    /**
     * Gets the config flag for security overview tab.
     * @returns {boolean} True if the security overview tab should be shown.
     */
    public getSecurityOverview(): boolean {
        return this.conditions?.securityTabs.overview;
    }

    /**
     * Gets the original config flag for security overview tab.
     * @returns {boolean} True if the security overview tab should be shown.
     */
    public getOriginalSecurityOverview(): boolean {
        return this.config?.securityTabs.overview;
    }

    /**
     * Gets the config flag for security email protection tab.
     * @returns {boolean} True if the security email protection tab should be shown.
     */
    public getSecurityWebmailProtection(): boolean {
        return this.conditions?.securityTabs.webmailProtection;
    }

    /**
     * Sets the config flag for security email protection tab.
     * @param {boolean} webmailProtection True if the security email protection tab should be shown.
     */
    public setSecurityWebmailProtection(webmailProtection: boolean): void {
        this.conditions.securityTabs.webmailProtection = webmailProtection;
    }

    /**
     * Sets the config flag for security overview tab.
     * @param {boolean} overview True if the security overview tab should be shown.
     */
    public setSecurityOverview(overview: boolean): void {
        this.conditions.securityTabs.overview = overview;
    }

    /**
     * Gets the original config flag for security email protection tab.
     * @returns {boolean} True if the security email protection tab should be shown.
     */
    public getOriginalSecurityWebmailProtection(): boolean {
        return this.config?.securityTabs.webmailProtection;
    }

    /**
     * Gets the original config flag for buy rights.
     * @returns {boolean} original buy rights flag value
     */
    public getOriginalBuyRights(): boolean {
        return this.config?.rights?.buy;
    }

    /**
     * Gets the config flag for buy rights.
     * @returns {boolean} buy rights flag value
     */
    public getBuyRights(): boolean {
        return this.conditions.rights.buy;
    }

    /**
     * Sets the config flag for buy rights.
     * @param {boolean} buyRights new flag value
     */
    public setBuyRights(buyRights: boolean): void {
        this.conditions.rights.buy = buyRights;
    }

    /**
     * Sets the config flag for buy more slots rights.
     * @param {boolean} buyRights new flag value
     */
    public setBuyMoreSlotsRights(buyMoreSlotsRights: boolean): void {
        this.conditions.rights.buyMoreSlots = buyMoreSlotsRights;
    }

    /**
     * Gets the original config flag for buy more slots rights.
     * @returns {boolean} original buy more slots rights flag value
     */
    public getOriginalBuyMoreSlotsRights(): boolean {
        return this.config?.rights?.buyMoreSlots;
    }

    /**
     * Gets the config flag for buy more slots rights.
     * @returns {boolean} buy more slots rights flag value
     */
    public getBuyMoreSlotsRights(): boolean {
        return this.conditions.rights.buyMoreSlots;
    }

    /**
     *
     * Get manifest path
     * @return {*}  {string}
     * @memberof ConfigService
     */
    public getPwaManifestPath(): string {
        return this?.conditions?.pwaSettings.manifestPath ?? "";
    }

    /**
     *
     * Get OS specific manifest path
     * @return {*}  {string}
     * @memberof ConfigService
     * @param {string} os
     */
    public getPwaManifestPathByOs(os): string {
        return this?.conditions?.pwaSettings.manifestByOs[os] ?? this.getPwaManifestPath();
    }

    /**
     *
     * Get Service worker path
     * @return {*}  {string}
     * @memberof ConfigService
     */
    public getPwaSwPath(): string {
        return this?.conditions?.pwaSettings.serviceWorkerPath ?? "";
    }
}
